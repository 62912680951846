.hoverup {
    transform: translateY(0px);
    transition: 0.4s;
}

.hoverup:hover {
    transition: 0.4s;
    transform: translateY(-3px);
}

.w100px {
    width: 100px;
}

::-webkit-inner-spin-button {
    display: none;
}

.barstyle {
    overflow: scroll;
}

.transi {
    transition: 0.7s ease-in-out;
}

.bgblur {
    backdrop-filter: blur(10px);
}

.logingrd {
    width: 100vw;
    background-image: url('../Assests//loginDas.jpg');
    background-repeat: no-repeat;
    background-position: cover;
}

.btngrd:hover {
    transition: 0.7s linear;
    background: rgb(114, 34, 185);
    background: linear-gradient(277deg, rgba(114, 34, 185, 1) 0%, rgba(51, 15, 83, 1) 100%);
}

.btngrd2 {
    background: rgb(114, 34, 185);
    background: linear-gradient(277deg, rgba(114, 34, 185, 1) 0%, rgba(51, 15, 83, 1) 100%);
}