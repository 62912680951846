@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.poppins {
  font-family: 'Poppins';
}

.linearGradient {
  background: rgb(234, 21, 177);
  background: linear-gradient(
    27deg,
    rgba(234, 21, 177, 1) 0%,
    rgba(219, 61, 194, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}

.transi {
  transition: 0.6s;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
  display: none;
}

.scrollbar2::-webkit-scrollbar {
  width: 10px;
  border-radius: 8px;
}

/* Track */
.scrollbar2::-webkit-scrollbar-track {
  border-radius: 8px;

  background: #f1f1f1;
  /* Color of the scrollbar track */
}

/* Handle */
.scrollbar2::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
  border-radius: 8px;
}

/* Handle on hover */
.scrollbar2::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar handle on hover */
  border-radius: 8px;
}

.bggrd {
  background: rgb(244, 232, 246);
  background: linear-gradient(
    201deg,
    rgba(244, 232, 246, 1) 0%,
    rgba(252, 215, 214, 1) 45%,
    rgba(243, 214, 242, 1) 100%
  );
}

.dascolbg {
  background-color: #fcf6f8;
}

.bgopacity {
  opacity: 0;
}

.bgopacity:hover {
  opacity: 1;
}

.tablebg {
  background-color: rgba(244, 244, 244, 0.9);
  border: 2px solid white;
  padding: 10px;
  padding-top: 0px;
}

.tablebg th {
  border-bottom: 2px solid rgb(216, 215, 215);
}

.tablebg th,
td {
  padding: 10px;
  text-align: center;
  vertical-align: top;
}

.table-responsive {
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #e0e0e0;
  /* For Firefox */
}

.table-responsive::-webkit-scrollbar {
  height: 8px;
  /* Height of horizontal scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
  background: #e0e0e0;
  /* Track color */
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Scrollbar color */
  border-radius: 10px;
  /* Roundness of the scrollbar */
  border: 2px solid #e0e0e0;
  /* Space around scrollbar */
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Color on hover */
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade {
  animation: fade 0.5s ease-in-out;
}
